import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { FC, useState, useEffect } from 'react'
import { tawreedTranslationService } from '../../../../../common/translations';
import { setFocus, TawreedCheckbox, TawreedFormField, TawreedInputNumber } from "../../../../../common/components/form";
import { CartService } from '../../domain';
import { CssUtils } from '../../../../../helpers/css';
import { Panel } from 'primereact/panel';
import { ObjectUtils } from '../../../../../helpers/object';
import { AuthUtils } from '../../../../auth/domain';
import { StoreProduct } from '../../../../catalog/store-products/data';

import './cart-form.scss';
import { DateUtils } from '../../../../../helpers/date';
import { IBonusOperations, IBonusType } from '../../../../constants';
import { Card } from 'primereact/card';
import { PrimeIcons } from 'primereact/api';
import { PriceUtils } from '../../../../../helpers/price';


export interface QuantityFormProps {
    visible: boolean;
    storeProduct: StoreProduct;
    back: ()=>void;

    store: string,
    customerId: number;
    changeQuantity: (quantity: number, bonusType: string) => void;
}

export const QuantityForm: FC<QuantityFormProps> = ({ visible, storeProduct, changeQuantity, back,customerId}) => {

    const service: CartService = React.useMemo(() => new CartService(), []);
    const [details, setDetails] = useState<any>({})
    const [quantity, setQuantity] = useState<number>(storeProduct.minimumOrderQuantity ?? 1)
    const [loader, setLoader] = useState<boolean>(false);
    const [bonusType, setBonusType] = useState(IBonusType.EXTRA_ITEMS);
    const [imageUrl, setImageUrl] = useState(storeProduct.imageUrl);
    const [sellingPrice, setSellingPrice] = useState<number>(0);
    const [priceLoader, setPriceLoader] = useState<boolean>(false);

    useEffect(() => {
        let mounted = true;

        if (mounted && visible) {
            setFocus('quantity', true);
        }
        return () => { mounted = false; };
    }, [visible]);

    useEffect(()=>{
        setImageUrl(storeProduct.imageUrl);
    },[storeProduct]);

    useEffect(() => {
        let time = setTimeout(() => {
            getDetails();
        }, 500)

        return () => clearTimeout(time);
    }, [quantity])

    const getDetails = () => {
        setLoader(true);
        if (storeProduct.storeProductId) {

            service.getItemDetails({ customerId: customerId,quantity: quantity, storeProductId: storeProduct.storeProductId, priceListId: storeProduct.priceListId }).then(res => {
                setDetails(res)
                setLoader(false);
                changeQuantity(quantity, bonusType);
            }).catch((res)=>{
                setLoader(false);
            });
        }
    }

    const getBox = (discount: string) => {
        if (discount === 'fixed')
            return "JOD"
        if (discount === 'percentage')
            return "%"

        return "%+"
    }
    // const getSellingPrice = () => {

    //     let discountType = 'fixed';
    //     if (storeProduct.discountType === 'percentage')
    //         discountType = 'percentage';
    //     if (storeProduct.discountType === 'bonus')
    //         discountType = 'bonus'
    //     let discountValue = 0;
    //     if (storeProduct.discountValue)
    //         discountValue = storeProduct.discountValue;
    //     let sellingPrice = storeProduct.price!;
    //     sellingPrice -= (discountType === 'fixed' ? discountValue : (storeProduct.price! * discountValue / 100));
    //     if (discountType === 'bonus')
    //         sellingPrice = storeProduct.price! - (storeProduct.price! * (discountValue / (discountValue + 100)))
    //     return sellingPrice;
    // }
    const priceUtils: PriceUtils=  new PriceUtils();
    useEffect(()=>{
        if(storeProduct)
            {
                setPriceLoader(true);
                priceUtils.getSellingPrice(storeProduct.price ?? 0, storeProduct.discountType ?? 'fixed', storeProduct.discountValue ?? 0)
                .then((res)=>{
                    setPriceLoader(false);
                setSellingPrice(res);
                }).catch((res)=>{
                    setPriceLoader(false);
                });
            }
    },[storeProduct]);

    const header = (type: IBonusType) => {
        return (
            <div className='inline-flex align-items-center'>

                {
                    <TawreedCheckbox render='standalone' checked={bonusType === type}
                        onChange={
                            () => { setBonusType(type); changeQuantity(quantity, type) }
                        }></TawreedCheckbox>

                }
                <span className='ml-2'>{type === IBonusType.EXTRA_ITEMS ?
                    tawreedTranslationService.translate('lbl_sales_cart_summary_bonus') :
                    tawreedTranslationService.translate('lbl_sales_cart_summary_discount')
                }</span>
            </div>);
    }
    return (
        <React.Fragment>
            <div className='grid'>
                    
                    <div className="col-6">
                        <div className='col-12'>
                            {visible &&
                                <React.Fragment>
                                    <TawreedFormField name="quantity" title="lbl_store_products_bonus_qty" className="field col-12 md:col-12">
                                        <span>
                                            {loader && <i className="pi pi-spinner pi-spin ml-2"></i>}
                                            <TawreedInputNumber
                                                autoFocus allowEmpty={false} min={1} max={1000} value={quantity} name="quantity" rules={{ required: "msg_field_is_required" }} render="form"
                                                onChange={(e) => {
                                                    console.log(e);
                                                    if (!(e && e.value && (e.value > 1000 || e.value <= 0)))
                                                        setQuantity(e.value || 0);
                                                    else
                                                        setQuantity(1);
                                                }}
                                            />
            
                                        </span>
            
                                    </TawreedFormField>
                                </React.Fragment>
                            }
            
                        </div>
                        <div className='col-12'>
                            <Panel style={{ cursor: 'pointer' }} header={header(IBonusType.EXTRA_ITEMS)}>
                                <div className="grid" >
            
                                    <div className="col-6">
                                        {tawreedTranslationService.translate('lbl_sub_total')}
                                    </div>
                                    <div className="col-6" style={CssUtils.align('right')}>
                                        {'JOD ' + ObjectUtils.toLocalString(details.sellingPrice * details.quantity)}
                                    </div>
                                    <div className="col-6">
                                        {tawreedTranslationService.translate('lbl_total_tax')}
                                    </div>
                                    <div className="col-6" style={CssUtils.align('right')}>
                                        {'JOD ' + ObjectUtils.toLocalString(details.itemTotalTax)}
                                    </div>
                                    <div className="col-6">
                                        {tawreedTranslationService.translate('lbl_bonus')}
                                    </div>
                                    <div className="col-6" style={CssUtils.align('right')}>
                                        {details?.availableBonus?.some((el: any) => el.bonusType === IBonusType.EXTRA_ITEMS) ? details?.availableBonus?.find((el: any) => el.bonusType === IBonusType.EXTRA_ITEMS).bonus : 0}
                                    </div>
                                    <div className="col-12"><hr></hr></div>
                                    <div className="col-6">
                                        {tawreedTranslationService.translate('lbl_total')}
                                    </div>
                                    <div className="col-6" style={CssUtils.align('right')}>
                                        {'JOD ' + ObjectUtils.toLocalString(details.sellingPrice * details.quantity + details.itemTotalTax)}
                                    </div>
            
                                </div>
                            </Panel>
                        </div>
                        <div className='col-12'>
                            <Panel style={{ cursor: 'pointer' }} header={header(IBonusType.DISCOUNT)}>
                                <div className="grid" >
            
                                    <div className="col-6">
                                        {tawreedTranslationService.translate('lbl_sub_total')}
                                    </div>
                                    <div className="col-6" style={CssUtils.align('right')}>
                                        {'JOD ' + ObjectUtils.toLocalString(details.sellingPrice * details.quantity)}
                                    </div>
                                    <div className="col-6">
                                        {tawreedTranslationService.translate('lbl_total_tax')}
                                    </div>
                                    <div className="col-6" style={CssUtils.align('right')}>
                                        {'JOD ' + ObjectUtils.toLocalString(details.itemTotalTaxUsingOffers)}
                                    </div>
                                    <div className="col-6">
                                        {tawreedTranslationService.translate('lbl_additional_discount')}
                                    </div>
                                    <div className="col-6" style={CssUtils.align('right')}>
                                        {'JOD ' + ObjectUtils.toLocalString(details?.availableBonus?.some((el: any) => el.bonusType === IBonusType.DISCOUNT) ? details?.availableBonus?.find((el: any) => el.bonusType === IBonusType.DISCOUNT).bonus : 0)}
                                    </div>
                                    <div className="col-12"><hr></hr></div>
                                    <div className="col-6">
                                        {tawreedTranslationService.translate('lbl_total')}
                                    </div>
                                    <div className="col-6" style={CssUtils.align('right')}>
                                        {'JOD ' + ObjectUtils.toLocalString(details.itemTotalAfterBonusDiscount)}
                                    </div>
            
                                </div>
                            </Panel>
                        </div>
                    </div>
            
                    <div className='col-6'>
            
                        <div className="col-12 md:col-12 proudctName">
                            <Card>
                                <div className='grid'>
                                    <div className='col-4'>
                                        {imageUrl &&
                                            <img onError={() => {
                                                let img = imageUrl.split("/products")[0] + "/categories/" + storeProduct?.categoryId?.toString() ?? "";
                                                if (imageUrl.indexOf("categories") === -1)
                                                    setImageUrl(img)
                                            }} src={imageUrl} width={'100%'} height={'100%'} alt='' className='p-button-rounded' />
            
                                        }
                                    </div>
                                    <div className='col-8'>
                                        <h4>
                                            {AuthUtils.current().language === 'en' ? storeProduct.titleEn : storeProduct.titleAr}
                                        </h4>
                                        <p>
                                            {tawreedTranslationService.translate('lbl_store_product_expire_date') + ": " + (storeProduct.expireDate && DateUtils.format(storeProduct.expireDate, 'date'))}
                                        </p>
                                    </div>
                                </div>
                            </Card>
                            <Card className='mt-4'>
                            <div className="grid pl-2 pr-2">
                                <div className="col-6">
                                    {tawreedTranslationService.translate('lbl_price_scheme_price_list_name')}
                                </div>
                                <div className="col-6" style={CssUtils.align('right')}>
                                    {storeProduct.priceListName}
                                </div>
                                <div className="col-6">
                                    {tawreedTranslationService.translate('lbl_price')}
                                </div>
                                <div className="col-6" style={CssUtils.align('right')}>
                                    {'JOD ' + ObjectUtils.toLocalString(storeProduct.price)}
                                </div>
                                <div className="col-6">
                                    {tawreedTranslationService.translate('lbl_discount')}
                                </div>
                                <div className="col-6" style={CssUtils.align('right')}>
                                    {ObjectUtils.toDiscount(storeProduct.discountType!) + ObjectUtils.toLocalString(storeProduct.discountValue)}
                                </div>
                                <div className="col-6">
                                    {tawreedTranslationService.translate('lbl_sellingPrice')}
                                </div>
                                <div className="col-6" style={CssUtils.align('right')}>
                                    {'JOD ' + ObjectUtils.toLocalString(sellingPrice)}
                                </div>
                                
                                <div className="col-6">
                                {tawreedTranslationService.translate('lbl_quantity')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {storeProduct.quantity ?? ""}
                            </div>

                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_min_order_qty')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {storeProduct.minimumOrderQuantity ?? ""}
                            </div>


                            <div className="col-6">
                                {tawreedTranslationService.translate('lbl_max_order_qty')}
                            </div>
                            <div className="col-6" style={CssUtils.align('right')}>
                                {storeProduct.maximumOrderQuantity ?? ""}
                            </div>


                                <div className="col-6">
                                    {tawreedTranslationService.translate('lbl_tax')}
                                </div>
                                <div className="col-6" style={CssUtils.align('right')}>
                                    {'% ' + ObjectUtils.toLocalString(storeProduct.tax)}
                                </div>
                                <div className="col-6">
                                    {tawreedTranslationService.translate('lbl_bonus')}
                                </div>
                                <div className="col-6" style={CssUtils.align('right')}>
                                    {
                                        storeProduct.bonuses !== undefined &&
                                        storeProduct.bonuses.length > 0 &&
                                        <React.Fragment>
                                            <div className="bonuses">
                                                {storeProduct.bonuses.map((e) => {
                                                    if (e.bonusType === IBonusType.EXTRA_ITEMS)
                                                        return <div className="bonuses-item">{e.quantity} + {e.bonus}</div>
                                                    return <></>
                                                })
                                                }
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                                <div className="col-6">
                                    {tawreedTranslationService.translate('lbl_Discounts')}
                                </div>
                                <div className="col-6" style={CssUtils.align('right')}>
                                    {
                                        storeProduct.bonuses !== undefined &&
                                        storeProduct.bonuses.length > 0 &&
                                        <React.Fragment>
                                        <div className="bonuses">
                                            {storeProduct.bonuses.map((e) => {
                                                if (e.bonusType === IBonusType.DISCOUNT)
                                                    return <div className="bonuses-item">{e.quantity}{e.bonusOperation === IBonusOperations.GREATER_THAN ? ">*" : " * "}  {e.bonus} {getBox(e.discountType!)}</div>
                                                return <></>
                                            })
                                            }
                                        </div>
                                    </React.Fragment>
                                    }
                                </div>
                            </div>
                            </Card>
                        </div>
                    </div>
            
                </div>
        </React.Fragment>
    )
}
