import { TawreedRoleName } from "../../../auth/domain";
import { UserRole } from "../models/role";

export const RoleMapper = {

    fromJson(from: any): UserRole {
        if (from) {
            if (from === 'STORE_ADMIN') {
                return UserRole.Store;
            } else if (from === 'STORE_DELIVERY') {
                return UserRole.StoreDelivery;
            } else if(from === 'STORE_SALES_MAN'){
                return UserRole.StoreSalesMan;
            }
        }
        return UserRole.Unknown;
    },

    toJson(from: UserRole): TawreedRoleName {
        if (from) {
            // 'ADMIN' | 'MANAGER' | 'SALES' | 'DELIVERY' | 'STORE_ADMIN' | 'CUSTOMER_ADMIN'
            if (from === UserRole.Administrator) {
                return 'ADMIN';
            } else if (from === UserRole.Manager) {
                return 'MANAGER';
            } else if (from === UserRole.Sales) {
                return 'SALES';
            } else if (from === UserRole.Delivery) {
                return 'DELIVERY';
            } else if (from === UserRole.Store) {
                return 'STORE_ADMIN';
            } else if (from === UserRole.Customer) {
                return 'CUSTOMER_ADMIN';
            }
            else if (from === UserRole.Finance) {
                return 'FINANCE';
            }
            else if (from === UserRole.SysAdmin) {
                return 'SYS_ADMIN';
            }
            else if (from === UserRole.StoreDelivery) {
                return 'STORE_DELIVERY';
            }
            else if (from === UserRole.TopUpAdmin) {
                return 'TOPUP_ADMIN';
            }
            else if (from === UserRole.WalletAdmin) {
                return 'WALLET_ADMIN';
            }
            else if (from === UserRole.CatManager) {
                return 'CATMANAGER';
            }
            else if(from === UserRole.StoreSalesMan){
                return 'STORE_SALES_MAN';
            }   

        }
        throw new Error();
    }
}
