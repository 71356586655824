import React, { useEffect, useMemo, useRef, useState } from "react";
import { tawreedTranslationService } from "../../../../../common/translations";
import { Button } from "primereact/button";
import { StoreProduct } from "../../../../catalog/store-products/data";
import { PartnerDto } from "../../../../partners/domain";
import { TawreedTable, TawreedTableProps } from "../../../../../common/components/table";
import { priceListDto, StoreProductsService } from "../../../../catalog/store-products/domain";
import { TawreedTableFormatters } from "../../../../../common/components/table/formatters";
import { ObjectUtils } from "../../../../../helpers/object";
import { PrimeIcons } from "primereact/api";
import { QuantityDialog } from "./quantity-dialog";
import { CssUtils } from "../../../../../helpers/css";
import { AuthUtils } from "../../../../auth/domain";
import { PriceListServcie } from "../../../../catalog/store-products/domain/services/price-list.service";
import { TawreedDropdown, TawreedFormField } from "../../../../../common";
export interface CartFormProductsState {
    isVisible: boolean;
    storeProduct?: StoreProduct;
}

export interface CartFormProductsProps {
    className?: string;
    onSelect: (p: StoreProduct) => void;
    disabled: boolean;
    zoneId: number;
    actor: PartnerDto;
    store: string,
    priceListId?: any;
    customerId: number;
    changeQuantity: (quantity: number, bonusType: string) => void
}
export interface StorePriceSearchState {

    stores: Array<PartnerDto>;
    priceScheme: number;
}
export const CartFormProducts: React.FC<CartFormProductsProps> = (props) => {
    const service: StoreProductsService = useMemo(() => new StoreProductsService(), []);
    const ref = useRef<any>(null);
    const [state, setState] = useState<CartFormProductsState>({
        isVisible: false,
        storeProduct: undefined,
    });

    const [priceLists, setPriceLists] = useState<Array<priceListDto>>([]);
    const priceListService: PriceListServcie = useMemo(() => new PriceListServcie(), []);

    useEffect(() => {
        if (priceLists.length == 0) {
            priceListService.getAll().then((res) => {
                setPriceLists(res);
            });
        }
    }, [props.zoneId]);

    const [reload, setReload] = useState<boolean>(false);
    const [search, setSearch] = useState<Partial<StorePriceSearchState>>({});
    
    var keyword : string | undefined= undefined;
    
    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        ds: {
            mode: 'lazy',
            asyncSearch: true,
            onSearch: (q) => {
                return service.searchSimilarWithPrice({ q: q.keyword ?? '',
                 zoneId: props.zoneId, actor: props.actor,
                  priceListId: props.priceListId ? props.priceListId : search.priceScheme,
                   storesList: search.stores ,customerId: props.customerId}, 
                {...q, page: reload || keyword !== q.keyword ? 0 : q.page}).then((res) => {
                
                return res;
            }).catch().finally(()=>{keyword = q.keyword; setReload(false)} );}
        },
        reload: reload,
        dataKey: 'storeProductId',
        name: 'tbl.store.products',
        columns: [
            {
                field: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr",
                header: "lbl_store_products_product_id",
                sortable: true,
                sortField: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr",
                filter: false,
                filterField: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr",
                
                style: {...CssUtils.width('20%')},
                bodyStyle: {'direction':'ltr'},
            },
            {
                field: "store.partnerName",
                header: "lbl_store_products_store_name",
                sortable: true,
                sortField: 'storeName',
                filter: false,
                filterField: 'storeName',
                style: CssUtils.width('20%'),
            },
            {
                field: "expireDate",
                header: "lbl_store_product_expire_date",
                sortable: true,
                sortField: 'expireDate',
                filter: false,
                filterField: 'expireDate',
                body: (options: any) => {

                    return options.expireDate ? new Date(options.expireDate).toDateString().split(' ').splice(1, 3).join(' ') : ''
                },
                style: CssUtils.width('12%'),
            },
            {
                field: "priceListName",
                header: "lbl_price_scheme_price_list_name",
                sortable: true,
                filter: false,
                style: CssUtils.width('12%'),
            },
            {
                field: "quantity",
                header: "lbl_quantity",
                sortable: true,
                filter: false,
                style: CssUtils.width('5%'),
            },
            {
                dataType: "numeric",
                field: "sellingPrice",
                header: "lbl_selling_price1",
                sortable: true,
                filter: false,
                // body: (options: any) => {
                //     let sellingPrice: number;
                //     if (options.discountType.toLowerCase() === "percentage") {
                //         sellingPrice = options.price - (options.price * options.discountValue / 100);
                //     }
                //     else if (options.discountType.toLowerCase() === 'bonus') {
                //         sellingPrice = (options.price * (options.discountValue / (options.discountValue + 100)))
                //     }
                //     else {
                //         sellingPrice = options.price - options.discountValue;
                //     }
                //     return <React.Fragment>{ObjectUtils.toLocalString(sellingPrice)}</React.Fragment>
                // },
                style: CssUtils.width('12%'),
                className: CssUtils.className.field.amount,
            },
            {
                dataType: "numeric",
                field: "tax",
                header: "lbl_store_products_tax",
                sortable: false,
                filter: false,
                ...TawreedTableFormatters.percentage('tax'),
                style: CssUtils.width('12%'),
                className: CssUtils.className.field.amount,
            },
            {
                field: "actions",
                // header: "lbl_add_to_cart",
                body: (options: any) => {
                    return <Button
                        type="button"
                        style={{ width: "100%", height: "35px" }}
                        icon={PrimeIcons.SHOPPING_CART}
                        className="p-button-success p-button-outlined"
                        disabled={options.quantity ===0 ? true: false}
                        onClick={() => {
                            props.onSelect(options);
                            setState(previous => { return { ...previous, isVisible: true, storeProduct: options }; });
                        }} />
                },
                style: CssUtils.width('12%'),
            }

        ],

        sort: {
            sortMode: 'single',
            initial: {
                sortField: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr",
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },

    }
    return (
        <React.Fragment>
                <div className="grid">
                {!props.priceListId &&
                    <TawreedFormField name="priceScheme" title="lbl_price_scheme_price_list_name" className="field col-6 md:col-6">
                        <TawreedDropdown disabled={props.priceListId} options={priceLists} name="priceScheme"
                            optionLabel="priceListName" optionValue="priceListId" render="standalone" value={search.priceScheme}
                            onChange={e => { setSearch({ ...search, priceScheme: e.value }); setReload(true); }} />
                    </TawreedFormField>
}
                </div>
            <TawreedTable {...tawreed} />

                <div style={{ display: 'none' }}>
                    <Button type="submit"
                        className="w-full"
                        ref={ref}
                        disabled={props.disabled}
                        label={tawreedTranslationService.translate('lbl_add_to_cart')} />
                </div>
            {
                state.isVisible &&
                state.storeProduct &&
                <QuantityDialog
                    customerId={props.customerId}
                    visible={state.isVisible}
                    storeProduct={state.storeProduct}
                    store={props.store}
                    changeQuantity={(quantity: number, bonusType: string) => {
                        props.changeQuantity(quantity, bonusType);
                    }}
                    hide={() => { setState({ storeProduct: undefined, isVisible: false }) }}
                    accept={() => {
                        if (ref.current && ref.current instanceof HTMLButtonElement) {
                            ref.current.click();
                            setState({ storeProduct: undefined, isVisible: false });
                        } else {
                            console.warn('ref.current && ref.current instanceof HTMLButtonElement');
                        }
                    }}
                ></QuantityDialog>}
        </React.Fragment>

    );
}
